import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"

import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';


const Kontakt = ({ data }) => (
  <>
  <Helmet
  title={data.wordpressPage.acf.kontakt_title_seo} 
  titleTemplate={data.wordpressPage.acf.kontakt_title_seo}
  meta={[
    {
      name: `description`,
      content: data.wordpressPage.acf.kontakt_description_seo, 
    },
    {
      property: `og:title`,
      content: data.wordpressPage.acf.kontakt_title_seo,
    },
    {
      name: `thumbnail`,
      content: data.wordpressPage.acf.kontakt_img_seo.localFile.url, 
    },
    {
      property: `og:image`,
      content: data.wordpressPage.acf.kontakt_img_seo.localFile.url, 
    },
    {
      property: `og:description`,
      content: data.wordpressPage.acf.kontakt_description_seo,
    },
    {
      property: `og:type`,
      content: `website`,
    },
  ]}
/>
    

    <div className="small-banner-content">
    <div title={data.wordpressPage.acf.kontakt_banner_img.alt_text} style={{backgroundImage: `url('` + data.wordpressPage.acf.kontakt_banner_img.localFile.url + `')`, backgroundPosition: `center`}} className="background-small"></div>
          <div className="text-on-banner">  
      
        </div>
        <div className='right-bottom-curve-cont'>
          <div className="right-bottom-curve"></div>
        </div>
        </div>

<Layout lang="pl" pageInfo={{ pageName: "kontakt" }}>
    <div className='contact'>
      <Container>
        <h2 className='title'>{data.wordpressPage.acf.kontakt_title}<span className='dot'>.</span></h2>
        <Row>
          <Col lg='4' className='box-container'>
          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' style={{height:'100%'}} duration='0.5'>
            <div className='inner-box'>
              <h1 className='head'>{data.wordpressPage.acf.first_box_title}</h1>
              <div className='text'>
                <ul className='info'>
                {data.wordpressPage.acf.first_box_text.map((value, index) => 
                  <li key={index}>{value.li}</li>
                )}
                </ul>
              </div>
            </div>
            </ScrollAnimation>
          </Col>
          <Col lg='4' md='6' className='box-container'>
          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' style={{height:'100%'}} delay='100' duration='0.5'>
            <a href={'tel:' + data.wordpressPage.acf.second_box_text.replace(/ /g, '')}>
              <div className='inner-box'>
                <h3 className='head'>{data.wordpressPage.acf.second_box_title}</h3>
                <div className='text'>
                  <ul className='info'>
                    <li>{data.wordpressPage.acf.second_box_text}</li>
                  </ul>
                </div>
              </div>
            </a>
            </ScrollAnimation>
          </Col>
          <Col lg='4' md='6' className='box-container'>
          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' style={{height:'100%'}} delay='200' duration='0.5'>
            <a href={'mailto:' + data.wordpressPage.acf.third_box_text}>
              <div className='inner-box'>
                <h3 className='head'>{data.wordpressPage.acf.third_box_title}</h3>
                <div className='text'>
                  <ul className='info'>
                    <li>{data.wordpressPage.acf.third_box_text}</li>
                  </ul>
                </div>
              </div>
            </a>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>





    </div>
</Layout> 
</>
)

export default Kontakt
 export const query = graphql`
 query atatata {
  wordpressPage(title: {eq: "Kontakt"}) {
    acf {
      kontakt_title_seo
      kontakt_description_seo
      kontakt_img_seo {
        localFile {
          url
        }
        alt_text
      }
      kontakt_banner_img {
        localFile {
          url
        }
        alt_text
      }
      kontakt_title
      first_box_title
      first_box_text {
        li
      }
      second_box_title
      second_box_text
      third_box_title
      third_box_text
    }
  }
}


 `
